.single-sub-account {
  display: flex;
}

.sub-account-header {
  text-align: center;
}

.sub-account-header .add-more-sub-account-btn {
  float: right;
}

.plus-icon-svg {
  cursor: pointer;
}

.minus-icon-svg {
  cursor: pointer;
}

.sub-account-row {
  background-color: rgba(0, 0, 0, 0.05);
}
.sub-account-row td {
  margin-left: 40px;
}

.sub-account-row li {
  list-style-type: none;
}

.plus-color {
  color: green !important;
}

.neg-color {
  color: red !important;
}

.signup-form {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 20px;
}
